export const darkTheme = {
    bg:"#00040a",
    sidebar:"#0d1116",
    sidebarBorder:"#0c0e11",
    sidebarHoverBG:"#11171d",
    sidebarText:"#c9d1d9",
    headerBG:"#242f38",
    headerBorder:"#1a1f25",
    submitButtonBG :"#58ad40",
    submitButtonHoverBG:"#2b8b31",
    submitTextHover:"#d2d8de",
    submitBorder:"#4db85b",
    confessionBG:"#0d1116",
    confessionBorder:"#5e6d7b",
    confessionTitle:"#ffffff",
    switchTextColor: "#ffffff",
    footerBG:"#161b22",
    footerBorder:"#1a1f25",
    labelColor:"#ffffff",
    textBoxBorder:"#5e6d7b",
    lockBG: "#58ad40",
    lockBorder:"#319a36",
    linkColor:"#58ad40",
    modeBG:"#b084d9",
}