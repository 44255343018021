export const lightTheme = {
    bg:"#e7e7e7",
    sidebar:"#f5f5f5",
    sidebarBorder:"#ececec",
    sidebarHoverBG:"#eeeeee",
    sidebarText:"#61727f",
    headerBG:"#32353d",
    headerBorder:"#EDE7E3",
    submitButtonBG :"#3799c1",
    submitButtonHoverBG:"#3aafde",
    submitTextHover:"#eaeaea",
    submitBorder:"#2e97c2",
    confessionBG:"#f5f5f5",
    confessionBorder:"#dbdbdb",
    confessionTitle:"#4a5465",
    switchTextColor: "#323842",
    footerBG:"#f6f6f6",
    footerBorder:"#dbdbdb",
    labelColor:"#61727f",
    textBoxBorder:"#c7c7c7",
    lockBG: "#3aaecb",
    lockBorder:"#2e83a5",
    linkColor:"#3799c1",
    modeBG:"transparent",
}